const About = () => {

    return (
        <div id="About">
            <h1 id='aboutMe'>ABOUT</h1>
            <p>Hello there, As you know me now, I'm a Software Engineer, I wasnt always one. Before this I was in the military for just over 9 years, crazy to see how fast that time went,
                but thats neither here nor there. Early in 2022 I decided to take a big leap into where I want to be in the future and decided to transition out of the military to become a Software Engineer.
                 In July 2022, I started my attendance at Galvanzie, a Code Boot Camp where I worked on several projects, some you'll see below, this journey has so much fun so far and I'm so excited to see where I will go.</p>
            <p>Outside of work I like to disconnect from the computer for a bit, some of my hobbies include: Fishing when the weather is nice, Gaming, relaxing with my family, and just enjoying the outdoors.
                 I would consider myself a family man, aswell as a pet loving one as well, my family consist of my beautiful wife, two beautiful daughters, three cats, two dogs.</p>

        </div>
    )

}

export default About;